.masterCustomerTable > .react-bootstrap-table th {
  border-top: 0;
  background-color: #5C068C !important;
  color: white;
  font-size: 17px !important;

}
.masterCustomerTable > .react-bootstrap-table td {
  padding: 1rem !important;
}
.partnerCol {
width: 15% !important;
}
.reqRespTable{
  padding-right: 15px !important;
  padding-left: 15px !important;
}
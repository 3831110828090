/* .radiobtn{
    height: 85px;
    width: 30px;
    background-color: lightgray;
    border: black;
    margin-right: 70px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: self-end;
    border-radius: 10px;
} */
.radiobtn {
    width: 100%;
    border: 1px solid #f8f6fa;
    background-color: #f8f6fa;
    border-radius: 10px;
    padding: 10px;
    min-height: 100px;
    text-align: center;    
}

.nextbtn{
    display: flex;
    justify-content: right;
}
.form-control:disabled, .form-control[readonly], .form-control:disabled:hover {
    background-color: #e9ecef !important;
}
#squareIcon{
    height: 15px;
    width: 30px;
    border: 2px solid #5C068C;
    position: relative;
    top: 2px;
}
/* #back{
    margin-right: 100px;
    padding: 5px 40px 5px 40px;
    background-color: black;
    color: white;
    border-radius: 2px;
} */
.customBorder {
    border: 2px solid #f8f6fa !important;
}
#resend{
    background-color: black;
    color: white;
    height: 33px;
}
.form-control-Synchrony {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;

    background-clip: padding-box;
    border: 1px solid #5C068C;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.text-violet {
    color: #5C068C !important;
}

/* #txtMobileNo:disabled {
    background-color: transparent !important;
} */

.border-dark-Synchrony {
    border-color: #5C068C !important;
    /*box-shadow: 0 0 0 0.1rem #5C068C !important;*/
}

.form-control-Synchrony:focus {
    box-shadow: 0 0 0 0.1rem #5C068C !important;
}

.border-dark-error {
    border-color: #ff0000 !important;
}

.form-control:focus {
    box-shadow: 0 0 0 0.1rem #f8f6fa !important;
}
.bg-white {
    background-color:#FFFFFF !important;
}

.border-dark{
    border-color: #BFB8D3 !important;
}

.fee_box {
    height: 540px;
}

.lap_box {
    height: auto;
}

.table_box_right {
    margin-top: 13px;
}

/* .container_terms .left-col, .container_terms .right-col {
    height: auto !important;
} */

input[type=checkbox]{
    min-width: 20px !important;
    min-height: 20px !important;
    accent-color: #5C068C;
}
input[type=radio]{
    accent-color: #5C068C;
}
.form-select{
    display: block;
    height: 100%;
    border-radius: 5px;
    width: 100%;
    background-color: white !important;
    color: #5C068C !important;
}

.form-select-border {
    border-color: #BFB8D3 !important;
}

.form-select-Synchrony {
    display: block;
    height: 100%;
    border-radius: 5px;
    width: 100%;
    background-color: white !important;
    color: #5C068C !important;
}

.form-select-Synchrony-border {
    border-color: #5C068C !important;
    /*box-shadow: 0 0 0 0.1rem #5C068C !important;*/
}

button.bg-secondary:hover {
    background-color: #5C068C!important;
    color: #f8f6fa !important;
}

div.ShowContent {
    display: block;
}

div.HideContent {
    display: none;
}

.ShowContent {
    display: block;
}

.HideContent {
    display: none;
}

.col-100 {
    width: 100%;
    float: none;
    clear: both;
    display: block;
}

#customModel > .modal-content {
    width: 180% !important;
    left: -40% !important;
}

/* .center_right_col_table tr {
    border: none !important;
    border-top: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
    border-left: none !important;
    border-right: none !important;
} */

.container_terms .bottom_table_data {
    width: 100%;
    /* border: 1px solid #000; */
}

.container_terms .bottom_table_data td {
    /* width: 100%; */
    border: 1px solid #000;
}

.container_terms .left-col {
    padding-right: 5px;
}
.container_terms .left-col, .container_terms .right-col {
    width: calc( 50% - 5px );
    display: inline-block;
    height: 327px;
}
.container_terms .right-col table {
    padding: 0px;
    border-spacing: 0px;
    font-size: 10px;
    border: 1px solid #000;
}
.container_terms .left-col td,
.container_terms .right-col td {
    border: 1px solid #000;
    line-height: 14px;
    font-size: 12px;
}
.container_terms > p a {
    text-decoration: underline;
}

.container_terms .left-col,
.container_terms .right-col {
    width: calc( 50% - 5px );
    display: inline-block;
    min-height: 200px;
}

.container_terms .left-col ~ .left-col,
.container_terms .right-col ~ .right-col {
    height: 133px;
}

.container_terms .left-col {
    padding-right: 5px;
}

.container_terms .left-col table,
.container_terms .right-col table {
    min-height: 100%;
}

.container_terms .left-col td,
.container_terms .right-col td {
    vertical-align: top;
}

.container_terms .left-col td:first-of-type,
.container_terms .right-col td:first-of-type {
    width: 90px;
    font-weight: normal;
}

.container_terms .two-col {
    padding: 5px 5px 5px 0;
    -webkit-columns: 2;
    -moz-columns: 2;
    -o-columns: 2;
    columns: 2;
}

.container_terms .two-col p {
    text-align: justify;
    margin: 0 0 5px;
}

.container_terms ~ p a {
    padding: 10px;
    display: block;
    width: 120px;
    margin: auto;
    color: #c42b26;
}

.react-datepicker__header {
    background-color: #f8f6fa !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range, .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: #5C068C !important;
    color: #f8f6fa !important;
    border-radius: 3px;
}
#disclosureHeading {
    display: none;
}
.tooltip > .tooltip-inner {
    min-width: 200%;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border: 1px solid #5C068C;
    /* background-color: #FFFFFF;
    color: #1b1b1b; */
}
.customTooltip > .tooltip-inner {
    border: 1px solid #5C068C;
     background-color: #FFFFFF;
     color: #5C068C !important;
     text-align: left !important;
     font-size: 16px !important;
}
@media (min-width : 1025px) {
    .customTooltip > .tooltip-inner {
        min-width: 200%;
        padding-top: .5rem;
        padding-bottom: .5rem;
        border: 1px solid #5C068C;
         background-color: #FFFFFF;
         color: #5C068C !important;
         text-align: left !important;
         font-size: 16px !important;
    }
    .financialPartner{
        max-width: 20% !important;
    }
  }
.captcha > span {
    font-family: 'Loved by the King', 'cursive' !important;
    background-image: linear-gradient(45deg,gray 5%, white 70%,gray);
    letter-spacing: 10px;
    border-radius: 5px;
}

.table_box {
    width: 325px;
    margin-top: 25px
}
@media (max-width: 767px) {
    #customModel > .modal-content {
        width: 100% !important;
        left: 0% !important;
    }
    .modal {
        padding-left: 0;
    }
    .container_terms .left-col, .container_terms .right-col {
        width: calc( 50% - 5px );
        display: initial;
    }
    .left-col  > .table {
        display: initial;
    }
    .tooltip > .tooltip-inner {
        min-width: min-content;
        overflow-x: hidden;
    }
    .fee_box {
        height: auto !important;
    }

    .table_box {
        width: auto;
        margin-top: 25px
    }
  }
  
  @media (min-width: 768px) and (max-width: 1024px)
  {
    .lap_box {
        height: auto;
    }

    #customModel > .modal-content {
        width: 100% !important;
        left: 0% !important;
    }
    .apply_table{
        height: 750px;
    }
    
    .tooltip > .tooltip-inner {
        min-width: min-content;
        overflow-x: hidden;
    }

    .fee_box {
        height: 920px;
    }

    /* .fee_box {
        height: auto !important;
    } */

    .table_box {
        width: auto;
        margin-top: 25px
    }
  }
  @media print{
      .printThisPage {
          display: none;
      }
        #disclosureHeading {
            display: block;
            margin-bottom: 1rem;
        }
        .container_terms .left-col td,
        .container_terms .right-col td {
            border: 1px solid #000;
            padding: 5px;
            line-height: 22px;
            font-size: 18px;
        }
        .table_box {
            width: 100%;
            min-height: fit-content !important;
        }
  }
.partnerDisable{
    background-color: #e9ecef;
  }
#fortivaDisclosure > p > span {
    margin: 0 !important;
 }
#fortivaDisclosure > p {
    margin-bottom: .5rem !important;
}
ul.listlevel1WW8Num1 > li:last-child > p {
    text-indent: 0px !important;
}
.popmsg{
text-align: center;
font-weight: bold;
}

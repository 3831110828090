.subCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 250px;
}
.acceptNotation {
    border-radius: 50px;
    border-color: green !important;
    background-color: lightgreen !important;
    color: green !important;
}
.pendingNotation {
    border-radius: 50px;
    border-color: gold !important;
    background-color: lightyellow !important;
    color: gold !important;
}
.declinedNotation {
    border-radius: 50px;
    border-color: red !important;
    background-color: lightsalmon !important;
    color: red !important;
}
@media (min-width : 1025px) {
    .col1 {
        min-width: 20% !important;
    }
}
.creditBtn{
    background-color: #220533;
}
.textColor{
    color: #5C068C;
}
@media (min-width: 990px) and (max-width:1190px){
    .customAlignment {
        min-width: 100% !important;
    }    
}

.fortivaOffers{
    position: absolute;
    bottom: 0px;
}
.fortivaOffers_daniels{
    position: absolute;
    top: 109px;
}
@media (max-width : 576px) {
    .applyOfferBtn {
        width: 100% !important;
    }
    .rejectOfferBtn {
        width: 100% !important;
        margin-top: 1.5rem !important;
    }
}
/* select{
    height: 25px;
    background-color: white !important;
    color: black !important;
    margin-right: 5px;
} */

#slct {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    /* background: green; */
    background-image: none;
    border: 1px solid black;
    background-color: white !important;
    color: black !important;
}
.toast-container {
    right: 1rem;
    z-index: 1999;
}
.select {
    position: relative;
    display: block;
    width: 3em;
    height: 2em;
    line-height: 2;
    /* background: #2C3E50; */
    /* overflow: hidden; */
    border-radius: .25em;
    margin-right: 5px;
}

#slct {
    height: 100%;
    margin: 0;
    padding: 0 0 0 .5em;
    /* color: #fff; */
    cursor: pointer;
}

#slct::-ms-expand {
    display: none;
}

.select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    /* //padding: 0 1em; */
    background:  #5C068C !important;
    color: white;
    /* width: 2em; */
    /* background-color: #5C068C !important; */
    pointer-events: none;
}

/* .select:hover::after {
    color: #F39C12;
} */

.select::after {
    -webkit-transition: .25s all ease;
    -o-transition: .25s all ease;
    transition: .25s all ease;
}
.continueToPortal{
    /* //width: 100%; */
    background-color: #5C068C !important;
}
.signOutSection {
    margin: 2rem;
}

.custom-control-label {
    transform: scale(1.5) !important;
}

@media (max-width: 786px) {
  .contentCards{
      min-height: 270px !important;
  }
  .signOutSection {
    margin: 1.3rem;
    }
}
option {
    padding: 5px 0;
}
#slct{
    width: 100%;
}
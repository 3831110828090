.cardBorder > .card-header {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}
.customBorder{
    border: 1px solid #BFB8D3 !important;
    border-radius: 5px;
}
.colorSwift{
    background: #F2EEF8;
}
.colorSwift:nth-child(2n) {
   background: #FFFFFF;
}
.wrapper {
    max-height: fit-content;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    overflow-x: auto;
}

@media (max-width: 767px){
    .desktopPosition{
        display: none;
    }
    .customBorder{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .colorSwift{
        background: #F2EEF8;
        min-height: 113px;
    }
}


@media (min-width: 1025px){
    .desktopPosition{
        display: block;
    }
    .mobilePosition{
        display: none;
    }
}

table {
  height: 200px;
  overflow-x: scroll;
}

.table {
  height: fit-content !important;
}

td.selection-cell>input {
  display: none;
}

input {
  border-color: white;
}

::placeholder {
  color: lightgrey !important;
}

#nomatchfound {
  position: relative;
  top: -110px;
}

#resulttable>thead>tr>th.vendors {
  width: 5%;
}
#resulttable>thead>tr>th.colHeader {
  width: 10%;
}
.searchColumn {
  min-width: 12.5%;
}
#outerCoverForLookUpTable {
  max-height: 500px !important;
  overflow: auto !important;
}
#outerCoverForLookUpCards {
  max-height: 1000px !important;
  overflow: auto !important;
}

#outerCoverForLookUpTable > .react-bootstrap-table > table > thead > tr> th {
  position: sticky !important;
  top: -1px !important;
  background: white !important;
  z-index: 1 !important;
}
th>svg {
  position: relative;
  top: -3px;
}

.table>thead>tr>th.strbalanceDue>span,
.table>thead>tr>th.maxCustBal>span {
  display: block !important;
}

.react-bootstrap-table th .caret-4-desc:before,
.react-bootstrap-table th .caret-4-asc:before {
  content: "\25b2" !important;
}

.react-bootstrap-table th .caret-4-desc:after,
.react-bootstrap-table th .caret-4-asc:before {
  content: "\25bc" !important;
}

.react-bootstrap-table th .order-4:before,
.react-bootstrap-table th .caret-4-asc:before {
  content: "\25b2" !important;
}

.react-bootstrap-table th .order-4:after,
.react-bootstrap-table th .caret-4-asc:after {
  content: "\25bc" !important;
}

.react-bootstrap-table {
  background-color: white;
  border-bottom: solid white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.card,
.card-body {
  border-radius: 15px;
}

.continueToPortal.bg-secondary:hover {
  background-color: #5C068C !important;
}
.venderCol {
  width: 5% !important;
}

.react-bootstrap-table-pagination-list {
  display: flex;
  justify-content: end;
  padding-right: 0 !important;
  margin-top: 1rem;
}

.row .react-bootstrap-table-pagination {
  width: 100%;
}

.col-md-6 .col-xs-6 .col-sm-6 .col-lg-6 {
  width: 50%;
}

.row .react-bootstrap-table-pagination>.col-md-6 .col-xs-6 .col-sm-6 .col-lg-6 {
  width: 50% !important;
}

.page-link {
  color: #1B1B1B;
  border: white;
}

.page-link:hover {
  z-index: 2;
  color: #1B1B1B;
  text-decoration: none;
  background-color: white;
  border-color: white;
}

.page-item {
  border-radius: 10px !important;
  background-color: white;
  color: black;
  border: white
}

.page-item.active .page-link {
  background-color: #5C068C;
  color: white;
  border: white;

}

.table thead th {
  vertical-align: top;
  border-bottom: 2px solid #dee2e6;
}

.table-hover tbody tr:hover {
  background-color: #F1EEF8;
}

thead {
  border-bottom: 1px solid black;
  color: #1B1B1B;
}

#cardnotfound {
  min-height: 150px;
}

.cardPagination {
  display: none;
}

.roundedStyle1 {
  border-radius: 15px;
}

.roundedStyle2 {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.roundedStyle3 {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

@media (max-width: 767px) {

  #resulttable, #resulttable>
  .table, .react-bootstrap-table {
    display: none;
  }

  .react-bootstrap-table-pagination {
    display: none;
  }
  
  .searchBtnColumn {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  #cardrow>.pagination {
    float: right;
    margin-bottom: 0;
    margin-top: 8px;
  }

  .magix {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: .5rem !important;
  }

  .goToAdminPage,
  .continueToPortal,
  .newCreditApplication {
    width: 100%;
  }

  #nomatchfound {
    display: none !important;
  }

  .searchColStart {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
  }

  .react-bootstrap-table {
    border-bottom: 0;
  }
}


@media (max-width: 420px) {
  .w-50 {
    width: 100% !important;
  }
}

@media(width:540px) {
  .searchColumn3>button {
    /* width: 100% !important; */
  }
}


@media (min-width: 768px) and (max-width: 1024px) {
  #resulttable {
    display: none;
  }

  .table {
    display: none;
  }

  .react-bootstrap-table-pagination {
    display: none;
  }

  .magix {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: .5rem !important;
  }

  #cardrow>.pagination {
    float: right;
    margin-bottom: 0;
    margin-top: 8px;
  }

  #nomatchfound {
    display: none !important;
  }
  .react-bootstrap-table {
    border-bottom: 0;
  }
  .searchColStart {
    border-top-left-radius: 15px !important;
  }
  .searchBtnColumn {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .searchColLastName {
    border-top-right-radius: 15px !important;
  }
  .searchColZipCode {
    border-bottom-left-radius: 15px !important;
  }


}

@media (min-width: 1025px) {
  .searchColStart {
    border-top-left-radius: 15px !important;
  }
  .searchColEnd {
    border-top-right-radius: 15px !important;
  }
  #cardrow {
    display: none;
  }

  #cardnotfound {
    display: none;
  }
}

.react-bootstrap-table th[data-row-selection] {
  padding: 0 !important;
}

.table-sm td {
  overflow-x: hidden !important;
  word-break: break-word !important;
}

#hamburger > .pro-sidebar {
  position: fixed;
  left: -270px;
}

#hamburger > .pro-sidebar.toggled {
  left: 0px;
}
.hamburger {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1090;
  background-color: rgba(0,0,0,0.5);
}
.react-datepicker-popper {
  z-index: 2 !important;
}
.storesTable > .react-bootstrap-table table th {
padding-top: 1.5rem;
padding-bottom: 1.5rem;
padding-left: 3rem;
padding-right: 3rem;
border-top: 0;
}
.storesTable > .react-bootstrap-table {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
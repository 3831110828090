.creditOrPrintSummary >  .react-bootstrap-table th[data-row-selection] {
 width: 30px;
}

.creditOrPrintSummary > .react-bootstrap-table th {
  border-top: 0;
  background-color: #5C068C;
  color: white;
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: 17px !important;
  width: 160px;
}
.account{
  width: 90px;
}
.store {
  width: 12% !important;
}

.accordion-header > button{
  border: 0 !important;
  background-color: white;
  font-size: 18px !important;
}
.accordion-button:after {
   content: "\25bc" !important;
   float: right;
   position: relative;
   transition: all 0.5s;
   margin-top: -290px;
   margin-right: 50px;
}
.accordion-button:not(.collapsed):after {
  content: "\25b2" !important;
  float: right;
   position: relative;
   transition: all 0.5s;
   margin-top: -290px;
   margin-right: 50px;
}

/* td.expand-cell:after {
  content: "\25bc" !important;
} */

/* .accordion-button::after {
	background-image: url("path_to_image");
} */
.creditOrPrintSummary>.react-bootstrap-table>table>thead>tr>th .order-4, .caret-4-desc {
  display: inline;
}
.headerName{
 width: 90px !important;
}
.appType{
  width: 120px !important;
}
.creditOrPrintSummary > .react-bootstrap-table th.expand-cell-header, .emptyCreditTable > .react-bootstrap-table th.expand-cell-header {
 width: 10% !important;
 text-align: center;
}
.appHistory >  .svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1.5em;
  overflow: visible;
  vertical-align: -0.125em;
  color: #5C068C;
}
.appCol{
  width: 60px !important;
}
.financial{
  display: inline-block;
  width: 21px;
  height: 21px;
  text-align: center;
  border: solid 1px;
  line-height: 18px;
  background-color: #28A745;
  margin-left: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Montserrat';
  color: #FFFFFF;

}
.hf{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Montserrat';
}
.footerNote{
  background-color: white;
  border: transparent;
  padding: 10px;
  /* border-radius: 12px;
  border: 1px solid #5C268C !important; */

}
.emptyCreditTable > .react-bootstrap-table > .table  {
  margin-bottom: 3rem;
}
#matchnotfound{
  position: relative;
  top: -40px;
}
@media (min-width: 768px) and (max-width: 1024px){
  .mr-md-4,
  .mx-md-4 {
    margin-right: 0rem !important;
  }
  #matchnotfound{
    position: relative;
    top: 99px !important;
    z-index: 1990 !important;
  }
}
@media (max-width: 767px) {
  #matchnotfound{
    position: relative;
    top: 99px !important;
    z-index: 1990 !important;
  }
}
.pending{
  background-color: #dec114
}
.declined{
  background-color: #FFFFFF;
  color: red;
  font-weight: bold;
  border: solid 2px;
}
.reApply{
  background-color: #9c75c6
}
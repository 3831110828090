@media (max-width: 767px) {
    #spliter {
        position: absolute;
        left: 44%;
        bottom: -12px;
        background-color: #FFFFFF;
    }
    .searchDiv {
        border-bottom: 1px solid lightgray;

        margin-bottom: 1rem;
        padding-bottom: 1rem;
    }
    #syncLookUpSearch {
        width: 100% !important;
    }
    #resultSpliter {
        position: absolute;
        left: 40%;
        top: 37%;
        background-color: #f8f6fa;
    }
}
@media (min-width: 768px) {
    #spliter {
        position: absolute;
        right: -20px;
        bottom: 44%;
        background-color: #FFFFFF;
    }
    .searchDiv {
        border-right: 1px solid lightgray;
    }
    #resultSpliter {
        position: absolute;
        left: 45%;
        top: 37%;
        background-color: #f8f6fa;
    }
}

/* table{
  height: 200px;
  overflow-x: scroll;
} */
/* .table{
  height: fit-content !important;
} */
td.selection-cell > input{
  display: none;
}
/* input{
  border-color: white;
} */
::placeholder{
  color: lightgrey !important;
}
/* f8f6fa */
#main{
  background-color: #f8f6fa !important;
  }
#nomatchfound{
  position: relative;
  top: -110px;
}
#resulttable_table > thead > tr > th {
  border-bottom: 0 !important;
}
#resulttable_table > tbody {
  height: 200px;
}
th > svg {
  position: relative;
  top: -3px;
}

.react-bootstrap-table>table>thead>tr>th .filter-label {
  border: none
}

select {
  appearance: menuList;
}

/* .react-bootstrap-table {
  border-top-color: solid #5C068C;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-top: 20px;
} */

.userRoleTable > .react-bootstrap-table {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  
}
.userRoleTable > .react-bootstrap-table th{
  border-top: 0;
}

table th:first-child{
  border-radius:15px 0 0 0;
}

table th:last-child{
  border-radius:0 15px 0 0;
}

.react-bootstrap-table th .caret-4-desc:before,  .react-bootstrap-table th .caret-4-asc:before{
  content: "\25b2" !important;
 
}
.react-bootstrap-table th .caret-4-desc:after, .react-bootstrap-table th .caret-4-asc:before {
  content: "\25bc" !important;
}
.react-bootstrap-table th .order-4:before, .react-bootstrap-table th .caret-4-asc:before{
  content: "\25b2" !important;
}
.react-bootstrap-table th .order-4:after, .react-bootstrap-table th .caret-4-asc:after{
  content: "\25bc" !important;
}
.react-bootstrap-table{
  background-color: white;
  border-bottom: solid white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.card, .card-body{
  border-radius: 15px;
}

.react-bootstrap-table-pagination-list{
  display: flex;
  justify-content: end;
  padding-right: 0 !important;
  margin-top: 1rem;
}

.row .react-bootstrap-table-pagination{
  width: 100%;
}

.col-md-6 .col-xs-6 .col-sm-6 .col-lg-6{
  width: 50%;
}

.row .react-bootstrap-table-pagination > .col-md-6 .col-xs-6 .col-sm-6 .col-lg-6{
  width: 50% !important;
}

.page-link{
  color: #1B1B1B;
  border: white;
}
.page-link:hover {
  z-index: 2;
  color: #1B1B1B;
  text-decoration: none;
  background-color: white;
  border-color: white;
}
.page-item{
  border-radius: 10px !important;
  background-color: white;
  color: black;
  border: white
}
.page-item.active .page-link{
  background-color: #5C068C;
  color: white;
  border: white;
 
}

.table thead th {
  vertical-align: top; 
 border-bottom: 2px solid #dee2e6;
 text-align: center;
}

.table td {
 text-align: center;
}

.table tbody tr {
  height: 50px !important
}

.table-hover tbody tr:hover {
  background-color: #F1EEF8;
}
thead{
  border-bottom: 1px solid black;
  color: #1B1B1B;
}

#cardnotfound{
  min-height: 150px;
}
.cardPagination{
  display: none;
}

.center-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
}


@media (max-width: 768px){
  #resulttable, #resulttable> .table{
    display: none;
  }
  .react-bootstrap-table-pagination {
    display: none;
  }
  
  #cardrow > .pagination {
    float: right;
    margin-bottom: 0;
    margin-top: 8px;
  }
  
  #nomatchfound{
   display: none !important;
  }
  .searchColumn1{
    border-radius: 15px 15px 0px 0px !important;
  }
  .searchColumn3{
    border-radius: 0px 0px 15px 15px !important;
  }
  .react-bootstrap-table {
    border-bottom: 0;
  }

  .input_width {
    width: 100% !important
  }

  .form_display {
    display: none
  }

  .mobile_card_view {
    display: flex;
    flex-direction: row;
  }
  
  .RolePanel {
    width: 90%  !important;    
  }
 
  .closeIcon{
    width: 100% !important;
  }
 
}

.input_width {
  width: 50%
}

@media (max-width: 420px)
{
  .w-50{
      width: 100% !important;
  } 
  
  .edit_button {
    border: none !important;
    background-color: transparent;
    color: #5C068C;
    width: 70px !important;
    border-radius: 5px;
    margin-left: 25px;
    padding: 9px;
  }
  
  .edit_button_unselect {
    border: none;
    background-color: transparent;
    opacity: 0.5;
    color: #5C068C;
    width: 70px !important;
    border-radius: 5px;
    margin-left: 25px;
    padding: 9px;
  }

 
}
@media(width:540px)
{
  .searchColumn3 > button{
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px)
{
  .form_pad {
    padding-top: 10px
  }
  #resulttable {
    display: none;
  }
  .table {
    display: none;
  }
  .react-bootstrap-table-pagination {
    display: none;
  }
  #cardrow > .pagination {
    float: right;
    margin-bottom: 0;
    margin-top: 8px;
  }
  
  #nomatchfound{
   display: none !important;
  }
  .searchColumn1{
    border-radius: 15px 0px 0px 15px !important;
  }
  .searchColumn3{
    border-radius: 0px 15px 15px 0px !important;
  }
  .react-bootstrap-table{
    border-bottom: 0;
  }   
  .RolePanel {
    width: 75%  !important;    
  }
  .closeIcon{
    width: 100% !important;
  } 
 
}

@media (min-width: 1025px) 
{
  #cardrow{
    display: none;
  } 
  
  .mobile_card_view {
    display: none;
  }
  #cardnotfound{
    display: none;
  }
  .searchColumn1{
    border-radius: 15px 0px 0px 0px !important;
  }
  .searchColumn3{
    border-radius: 0px 15px 0px 0px !important;
  }
  .RolePanel {
    width: 40%  !important;   
  } 
 
  
}


.react-bootstrap-table th[data-row-selection] {
  padding: 0 !important;
}
.table-sm td{
  overflow-x: hidden !important;
  word-break: break-word !important;
}

.add_button {
  border: none;
  background-color: transparent;
  color: #5C068C;
  border-radius: 5px;
  padding: 9px;
 
}

.edit_button {
  border: none;
  background-color: transparent;
  color: #5C068C;
  width: 90px;
  border-radius: 5px;
  margin-left: 25px;
  padding: 9px;
}

.edit_button_unselect {
  border: none;
  background-color: transparent;
  opacity: 0.5;
  color: #5C068C;
  width: 90px;
  border-radius: 5px;
  margin-left: 25px;
  padding: 9px;
}

.inputForm {
  background-color: white;
  width: 85%;
  border:solid 2px gray;    
  outline:solid 2px #e3e0ed;
  margin-bottom:25px;
}

.input_form_control input, .input_form_control select {
  background-color: #5C068C !important;
}

.input_form_control select, .input_form_control input {
  color: white !important;
}
.inputForm:focus {
  border:solid 2px gray;    
  outline:solid 2px #e3e0ed;
}

.cancel {
  padding: 10px;
  border: none;
  background-color: #f1eef8;
  color: #5C068C;
  border-radius: 5px;
}
.save{
  padding: 10px;
  border: none;
  background-color: #5C068C;
  color: white;
  border-radius: 5px;
  margin-left: 25px;
}

.EditRoleinput {
  background-color: white !important;
  color: black !important;
  outline: none !important;
  border: solid 2px #e3e0ed !important;
}

.EditRoleinput:active, .EditRoleinput:focus {
  outline: solid 2px #e3e0ed !important;
  border: none !important;
}

.RolePanelHide{
  display: none;
}

.RolePanelShow{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1010;
}

.RolePanel {
  width: 50%;
  float: right;
  background-color: white;
  height: 100%;
  overflow-y: auto;
}

.header-class {
  background-color: #5C068C;
  color: white;
  border-radius: 15px 15px 0px 0px !important;
}

.table_header_class {
  padding-left: 12% !important;
  padding-right: 12% !important;
  font-size: 19px;
  /* text-align: left !important; */
}

.text_box_style{
  color: #5C068C !important;
  background-color: white !important;
  outline: 1px solid black;
}

.command_bar{
  background-color: white;
  border: transparent;
  padding: 10px;
  border-radius: 12px;

}

.dropdown{
  background-color: white !important;
  color: black !important;
}

.closeIcon{
  display: flex;
  justify-content: end;
  align-items: end;
  margin-top: -34px;
  /* width: 90%; */
}

.warning{
  font:12px;
  color: red;
}

/* .deletefooter{
  justify-content: center;
} */

#no_found_table {
  display: none
}

.table_desktop {
  display: block
}

@media (max-width: 1024px) {
  #no_found_table {
    display: block
  }

  .table_desktop {
    display: none
  }
}

tr:nth-child(even) {
  background-color: #f1eef8;
}

.selected_row_class  {
  background-color: #5C068C  !important;
  color : white !important;
  /* border: 1px solid #5C068C; */
}

.selected_row_class td:last-child {
  border-left-color: #5C068C;
  /* border-radius: 0 15px 0 0; */
  border-left-width: 1px;
  border-left-style: solid;
}
/* tr:nth-child(odd) td {
  background-color: white;
  
} */

table th:last-child {
  border-left-color: #5C068C;
  border-radius: 0 15px 0 0;
  border-left-width: 1px;
}

.sort_desc::after {
  content: "\25bc" !important;
}

.sort_desc::before {
  content:  "\25b2" !important;
  opacity: 0.4;
}

.sort_asc::after {
  content: "\25bc" !important;
  opacity: 0.4;
}

.sort_asc::before {
  content:  "\25b2" !important;
}

.sort_asc_desc::after {
  content: "\25bc" !important;
  opacity: 0.4;
}

.sort_asc_desc::before {
  content:  "\25b2" !important;
  opacity: 0.4;
}
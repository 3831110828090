#acknowledgeLabel, #paperLabel, #electroniccopyLabel {
    position: relative;
    top: -5px;
    padding: 0 .25rem 0 .25rem;
    margin-bottom: 0;
}
#insurenceHyperLink
{
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    position: relative;
    top: -4px;
    line-height: 0 !important;
}
#insuranceBackArrowBtn, #insuranceCloseBtn {
    cursor: pointer;
}
#insuranceBackArrowBtn {
    transform: scale(1.5);
}
.table-footer > tr > td{
    display: none !important;
}

@media print {
    .print-component {
        display: table;
        font-size: 13px !important;
    }
    #NVprint-component {
        font-size: 17px !important;
    }
    .table-footer > tr > td{
        display: flex !important;
        color: #1b1b1b;
    }
}
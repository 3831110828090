body{
  font-family: 'Montserrat', sans-serif !important;
  background-color: #f8f6fa !important;
  color: #1B1B1B !important;
}
#App{
  height: 100vh;
  background-color: #f8f6fa !important;
}
#danielLogo ,.toggleBtn{
  display: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.navbar, .navbar .container, .navbar .container-fluid, .navbar .container-lg, .navbar .container-md, .navbar .container-sm, .navbar .container-xl {
  flex-wrap: nowrap !important;
}
.vendorDenotation {
  color: #B16ED7 !important;
}
.react-bootstrap-table table {
  table-layout: initial !important;
}
@media (min-width : 1024px) {
  #tenderFortiva, #tenderSynchrony, #danielsAutoPay {
    min-width: 1030px !important;
  }
  #tender {
    min-width: 800px !important;
  }
}
@media( max-width: 961px) {
  #danielLogo ,.toggleBtn{
    display: inline !important;
  }
  .backToCustomerLookUp{
    margin-right: auto;
  }
}

.overlay{
  position:absolute;  
  width:100%;
  height:100%;
  background:grey;
  opacity: .5; 
  pointer-events: none;
}

.without_overlay{
  height:100%;
  pointer-events: all;
}

#emailLinkToastContainer {
  position: absolute !important;
  top: 0 !important;
}
